/* For use in src/lib/core/theming/_palette.scss */
$app-primary-palette: (
  50: #e7f3f9,
  100: #c3e0f0,
  200: #9bcce7,
  300: #72b8dd,
  400: #54a8d5,
  500: #3699ce,
  600: #3091c9,
  700: #2986c2,
  800: #227cbc,
  900: #166bb0,
  A100: #e3f1ff,
  A200: #b0d9ff,
  A400: #7dc0ff,
  A700: #63b4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$app-warn-palette: (
  50: #fde5e8,
  100: #fabdc5,
  200: #f7919e,
  300: #f46577,
  400: #f14459,
  500: #ef233c,
  600: #ed1f36,
  700: #eb1a2e,
  800: #e81527,
  900: #e40c1a,
  A100: #ffffff,
  A200: #ffdbdd,
  A400: #ffa8ac,
  A700: #ff8f94,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
