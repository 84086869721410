// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $dashboard-deamicis-primary: mat.define-palette(mat.$indigo-palette);
$dashboard-deamicis-primary: mat.m2-define-palette(
  (
    50: #e7f3f9,
    100: #c3e0f0,
    200: #9bcce7,
    300: #72b8dd,
    400: #54a8d5,
    500: #3699ce,
    600: #3091c9,
    700: #2986c2,
    800: #227cbc,
    900: #166bb0,
    A100: #e3f1ff,
    A200: #b0d9ff,
    A400: #7dc0ff,
    A700: #63b4ff,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  )
);
$dashboard-deamicis-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
// $dashboard-deamicis-warn: mat.define-palette(mat.$red-palette);
$dashboard-deamicis-warn: mat.m2-define-palette(
  (
    50: #fde5e8,
    100: #fabdc5,
    200: #f7919e,
    300: #f46577,
    400: #f14459,
    500: #ef233c,
    600: #ed1f36,
    700: #eb1a2e,
    800: #e81527,
    900: #e40c1a,
    A100: #ffffff,
    A200: #ffdbdd,
    A400: #ffa8ac,
    A700: #ff8f94,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  )
);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dashboard-deamicis-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $dashboard-deamicis-primary,
      accent: $dashboard-deamicis-accent,
      warn: $dashboard-deamicis-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dashboard-deamicis-theme);

$primary: #3699ce;
$red: #ef233c;
$green: #70e000;

$error: $red;
$success: $green;

@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./app/theme/palette.scss";

// @import "./app/theme/fonts/be-vietnam-pro.font.scss";
@import "./app/theme/fonts/geologica.font.scss";

html,
body {
  height: 100%;
}
* {
  --dea-radius: 5px;
  --dea-spacer: 24px;
  --text-color: #262626;
  --primary-color: #3699ce;
  --dea-transition-time: 0.35s;
  --accessibility-blue: #0077ed;
  --background-color: 241, 241, 241;
  --dea-transform-scale: 1.05;
  --dea-input-border: 1px solid rgba(46, 46, 46, 0.1);
  --dea-input-background: transparent;
  --dea-font-family: "Geologica" !important;
  --success-color: #70e000;
  --warning-color: #f4bf4f;
  --failure-color: #ef233c;
  --dea-side-drawer-w: 240px;
  --dea-main-dashboard-navbar-h: 40px;
}

body {
  margin: 0;
  color: var(--text-color);
  background-color: rgb(var(--background-color));
  font-family: var(--dea-font-family);
  font-weight: 300 !important;
  font-size: 1rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
img {
  font-family: var(--dea-font-family);
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  text-decoration: none;
  color: unset;
}

*:not(.shadow-lg):not(.shadow):not(.shadow-sm):focus {
  box-shadow: none !important;
}

input:focus {
  border-color: var(--accessibility-blue);
}

.dea-shadow {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(46, 46, 46, 0.045) !important;
  box-shadow: 0px 3px 6px 0px rgba(46, 46, 46, 0.045) !important;
}

.dea-radius {
  border-radius: var(--dea-radius);
}

.dea-radius-start {
  border-top-left-radius: var(--dea-radius);
  border-bottom-left-radius: var(--dea-radius);
}

.dea-radius-end {
  border-top-right-radius: var(--dea-radius);
  border-bottom-right-radius: var(--dea-radius);
}

.dea-radius-top {
  border-top-left-radius: var(--dea-radius);
  border-top-right-radius: var(--dea-radius);
}

.dea-radius-bottom {
  border-bottom-left-radius: var(--dea-radius);
  border-bottom-right-radius: var(--dea-radius);
}

.dea-border {
  border: var(--dea-input-border);
}

.dea-border-025 {
  border: var(--dea-input-border);
  border-width: 0.25px !important;
}

.bg-w {
  background-color: #fff;
}

.dea-padding {
  padding: var(--dea-spacer);
}

.data-box {
  min-width: 200px;
}

.dea-form-input {
  background: var(--dea-input-background);
  border-radius: var(--dea-radius);
  border: var(--dea-input-border);
  font-weight: 400;
}

input[auth-input] {
  font-size: 1rem !important;
  height: 48px !important;
  font-weight: 300;
}

.input-wrapper:focus-within {
  border-color: rgba(0, 119, 237, 0.5) !important;
}

.dea-mat-icon-btn {
  cursor: pointer;
}

.h-spacer {
  width: var(--dea-spacer) !important;
  min-width: var(--dea-spacer) !important;
  max-width: var(--dea-spacer) !important;
}

.v-spacer {
  height: var(--dea-spacer) !important;
  min-height: var(--dea-spacer) !important;
  max-height: var(--dea-spacer) !important;
}

.user-img-spacer {
  min-width: 64px !important;
  height: 64px !important;
}

::placeholder {
  color: rgba(46, 46, 46, 0.5) !important;
  font-weight: 300;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(46, 46, 46, 0.5) !important;
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(46, 46, 46, 0.5) !important;
  font-weight: 300;
}

// FONT-SIZE
.fs-055r {
  font-size: 0.55rem;
}

.fs-065r {
  font-size: 0.65rem;
}

.fs-075r {
  font-size: 0.75rem;
}

.fs-085r {
  font-size: 0.85rem;
}

.dea-gap {
  gap: var(--dea-spacer) !important;
}

// TABLE
.dea-table-row-header {
  height: 32px !important;
}

.dea-table-row-header > th {
  font-weight: 300 !important;
  padding-top: 0 !important;
  opacity: 0.35;
}

.dea-table-row > td {
  background-color: transparent;
}

.dea-table-row:hover {
  cursor: pointer;
}

.dea-table-row-hover-bg:hover {
  background-color: rgba(46, 46, 46, 0.045);
}

// FAKE-TABLE
.dea-fake-table-row-header {
  height: 32px !important;
}

.dea-fake-table-row-header > div {
  font-weight: 300 !important;
  padding-top: 0 !important;
  opacity: 0.35;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-65 {
  opacity: 0.65;
}

.dea-hover-underline {
  transition: var(--dea-transition-time);
  cursor: pointer;
}
.dea-hover-underline:hover {
  transition: var(--dea-transition-time);
  text-decoration: underline;
  cursor: pointer;
}

.dea-icon-btn {
  transition: var(--dea-transition-time);
  opacity: 0.45;
}

.dea-icon-btn:hover {
  transition: var(--dea-transition-time);
  cursor: pointer;
  opacity: 0.75;
}

.dea-hover-effect {
  transition: var(--dea-transition-time);
  transform: 1;
}

.dea-hover-effect:hover {
  transform: scale(var(--dea-transform-scale));
  transition: var(--dea-transition-time);
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.fast-action-btn {
  transition: var(--dea-transition-time) !important;
}

.fast-action-btn:hover {
  transition: var(--dea-transition-time) !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.pointer {
  cursor: pointer;
}

.space-nowrap {
  white-space: nowrap;
}

.dea-mat-chip-option.selected.mat-mdc-standard-chip:not(
    .mdc-evolution-chip--disabled
  )
  .mdc-evolution-chip__text-label {
  color: white !important;
}

.dea-mat-chip-option.selected.mat-mdc-standard-chip:not(
    .mdc-evolution-chip--disabled
  )
  .mdc-evolution-chip__checkmark {
  color: white !important;
}

.row-cols-7 > * {
  flex: 0 0 14.2857142857%; /* 100% / 7 */
  max-width: 14.2857%;
}

.border-025 {
  border-width: 0.25px !important;
}

.w-cols-7 {
  width: calc(100% / 7);
  max-width: calc(100% / 7);
}

.checkbox-btn-container label:hover,
.checkbox-btn-container input[type="checkbox"]:checked + label {
  color: white !important;
  background-color: var(--primary-color) !important;
}

.pencil-cursor:hover {
  cursor: url("/assets/icons/pencil-cursor.svg"), auto;
}

.no-select {
  user-drag: none !important;
  user-select: none !important;
}
